// palette for Supreme Play Casino
$bg-color: #0a0f00;
$text-color: #f5ffee;
$primary: #93ff1d;
$success: #62aa13;
$info: #1dfffa;
$danger: #ff1d22;

//hamburger-menu in primary color
$navbar-dark-icon-color: $primary;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");



$secondary: mix($bg-color, #fff, 50%);
$input-placeholder-color: $secondary;

#rivalHeaderNavAndLogoSection {
  img {
    margin-top: -5px;
    max-height: 60px;
    max-width: 200px !important;
    margin-bottom: -5px;
  }
}


.sticky-sidebar {
  .nav-link:hover {
    color: $primary !important;
    .casino-icon path {
      fill: $primary !important;
    }
  }
  .active-link {
    color: $primary !important;
    .casino-icon {
      opacity: 1;
      path {
        fill: $primary !important;
      }
    }
  }
}


#category-nav li button {
  &:hover, &.active {
    --bs-nav-pills-link-active-color: #{$bg-color} !important;
  }
}
